<template>
  <v-expansion-panel
    v-model="panel"
    class="expansion-panel-border"
  >
    <v-expansion-panel-header @click="expand">
      <v-row
        no-gutters
        align="center"
      >
        <v-col
          cols="12"
          md="7"
        >
          <div class="mb-1 subtitle-1">
            {{ readablePropertyType }} • {{ detailedValuation.inputs.address.street }} {{ detailedValuation.inputs.address.houseNumber }} •
            {{ detailedValuation.inputs.address.zip }} {{ detailedValuation.inputs.address.city }}
          </div>
          <div class="grey--text">
            {{ readablePrice }}
          </div>
        </v-col>

        <v-col
          cols="12"
          md="5"
          class="pr-2 mt-5 text-md-right grey--text mt-md-0"
        >
          <span class="mr-8">{{ formatDate(detailedValuation.createdAt) }}</span>

          <v-tooltip
            v-if="showLeadIcon"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :aria-label="$t('valuation.valuation-result.tooltips.assigned-to-contact')"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                mdi-account
              </v-icon>
            </template>
            <span>{{ $t('valuation.valuation-result.tooltips.pdf-generating') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="isPdfComplete"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                aria-label="PDF Generierung abgeschlossen"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                mdi-file-check
              </v-icon>
            </template>
            <span>{{ $t('valuation.valuation-result.tooltips.pdf-finished') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pb-4">
      <div v-if="valuationDetailsLoaded">
        <v-divider class="mb-4" />
        <!-- Input and Results -->
        <v-row
          no-gutters
          class="mt-0"
        >
          <v-col
            cols="12"
            md="6"
            class="mt-2 ma-0 pa-0 mt-md-0"
          >
            <p class="font-weight-bold">
              {{ $t('valuation.valuation-result.inputs') }}
            </p>
            <v-row>
              <v-col
                md="6"
                class="pt-0 mt-0"
              >
                <v-list dense>
                  <v-list-item class="px-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('valuation.labels.address') }}:
                      </v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ detailedValuation.inputs.address.street }} {{ detailedValuation.inputs.address.houseNumber }}
                      </div>
                      <div class="grey--text text--darken-3 body-2">
                        {{ detailedValuation.inputs.address.zip }} {{ detailedValuation.inputs.address.city }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="px-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('valuation.labels.object-type') }}:
                      </v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ $t(propertyTypeLabel) }}
                        <span v-if="propertySubTypeLabel">
                          ({{ $t(propertySubTypeLabel) }})
                        </span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="px-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('valuation.labels.condition') }}:
                      </v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ $t(condition) }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="px-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('valuation.labels.quality') }}:
                      </v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ $t(quality) }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col
                md="6"
                class="pt-0 mt-0"
              >
                <v-list dense>
                  <v-list-item class="px-0">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('valuation.labels.building-year') }}:</v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ detailedValuation.inputs.buildingYear }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="detailedValuation.inputs.type === PropertyType.MULTI_FAMILY_HOUSE"
                    class="px-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('valuation.labels.annual-rental-income') }}:</v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ readableAnnualIncome }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="detailedValuation.inputs.type === PropertyType.MULTI_FAMILY_HOUSE"
                    class="px-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('valuation.labels.units') }}:</v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ detailedValuation.inputs.numberOfUnits }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-else
                    class="px-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('valuation.labels.rooms') }}:</v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ detailedValuation.inputs.numberOfRooms }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="px-0">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('valuation.labels.living-area') }}:</v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ detailedValuation.inputs.livingArea }} {{ formatSpaceUnit() }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="detailedValuation.inputs.type === PropertyType.HOUSE"
                    class="px-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('valuation.labels.land-area') }}:</v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ detailedValuation.inputs.landArea }} {{ formatSpaceUnit() }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="detailedValuation.inputs.type === PropertyType.APARTMENT"
                    class="px-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('valuation.labels.balcony-area') }}:</v-list-item-title>
                      <div class="grey--text text--darken-3 body-2">
                        {{ detailedValuation.inputs.balconyArea }} {{ formatSpaceUnit() }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <!-- Additional Information -->
            <v-row
              no-gutters
              align="center"
              class="hidden-sm-and-down"
            >
              <v-col
                cols="12"
                class="mt-6 ma-0 pa-0 mt-md-0"
              >
                <p class="font-weight-bold">
                  {{ $t('valuation.valuation-result.additional-info') }}
                </p>
                <v-row class="pt-0">
                  <v-col
                    md="6"
                    class="pt-0"
                  >
                    <v-list dense>
                      <v-list-item
                        v-if="user"
                        class="px-0"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t('valuation.labels.created-by') }}:
                          </v-list-item-title>
                          <div class="grey--text text--darken-3 body-2">
                            {{ user.firstname }} {{ user.lastname }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="detailedValuation.provider === 'PRICE_HUBBLE' || detailedValuation.provider === 'SPRENGNETTER' || valuation.provider === 'IMMOSPARROW'"
                        class="px-0"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <div class="d-flex">
                              <span>{{ $t('valuation.labels.valuation-quality') }}:</span>
                              <information-button
                                :title="$t('valuation.labels.valuation-quality')"
                                :content="$t('valuation.valuation-result.confidence-content')"
                                class="ml-2"
                              />
                            </div>
                          </v-list-item-title>
                          <div class="grey--text text--darken-3 body-2">
                            {{ $t(confidence) }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col
                    md="6"
                    class="pt-0"
                  >
                    <v-list dense>
                      <v-list-item
                        v-if="lead"
                        class="px-0"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t('valuation.labels.created-by-agent') }}:
                          </v-list-item-title>
                          <div class="grey--text text--darken-3 body-2">
                            <a
                              :href="`/leads/${lead.id}`"
                            > {{ leadName }}</a>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="mt-4 chart mt-md-0 mb-lg-12"
          >
            <p class="font-weight-bold">
              {{ $t('valuation.valuation-result.result-label') }}
            </p>
            <ValuationResultChart
              v-if="detailedValuation.results"
              :results="detailedValuation.results"
            />
            <div
              v-else
              class="grey--text"
            >
              {{ $t('valuation.valuation-result.result-calculating') }}
              <v-progress-circular
                :size="15"
                :width="2"
                indeterminate
                color="primary"
                class="ml-2 mr-2"
                small
              />
            </div>
          </v-col>

          <!-- Additional Information Mobile-->
          <v-row
            no-gutters
            align="center"
            class="hidden-md-and-up"
          >
            <v-col
              cols="12"
              class="mt-16 ma-0 pa-0 mt-md-0"
            >
              <p class="font-weight-bold">
                {{ $t('valuation.valuation-result.additional-info') }}
              </p>
              <v-row class="pt-0">
                <v-col
                  md="6"
                  class="pt-0"
                >
                  <v-list dense>
                    <v-list-item
                      v-if="user"
                      class="px-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('valuation.labels.created-by') }}:
                        </v-list-item-title>
                        <div class="grey--text text--darken-3 body-2">
                          {{ user.firstname }} {{ user.lastname }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="detailedValuation.provider === 'PRICE_HUBBLE' || detailedValuation.provider === 'SPRENGNETTER' || valuation.provider === 'IMMOSPARROW'"
                      class="px-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="d-flex">
                            <span>{{ $t('valuation.labels.valuation-quality') }}:</span>
                            <information-button
                              :title="$t('valuation.labels.valuation-quality')"
                              :content="$t('valuation.valuation-result.confidence-content')"
                              class="ml-2"
                            />
                          </div>
                        </v-list-item-title>
                        <div class="grey--text text--darken-3 body-2">
                          {{ $t(confidence) }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col
                  md="6"
                  class="pt-0"
                >
                  <v-list dense>
                    <v-list-item
                      v-if="lead"
                      class="px-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('valuation.labels.created-by-agent') }}:
                        </v-list-item-title>
                        <div class="grey--text text--darken-3 body-2">
                          <a
                            :href="`/leads/${lead.id}`"
                          > {{ leadName }}</a>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>

        <v-row
          v-if="canAssignLeadToValuation"
          class="pt-0"
        >
          <v-col
            cols="12"
            class="pt-0"
          >
            <div
              class="d-flex flex-column flex-lg-row"
            >
              <AssignValuationDialog
                v-if="!lead"
                :valuation-id="detailedValuation.id"
                class="mr-lg-4 mb-3 mb-lg-0"
                @assigned="updateValuation"
              />

              <v-btn
                v-if="lead"
                small
                outlined
                color="primary"
                class="mr-lg-4 mb-3 mb-lg-0"
                :class="{'px-12':!isPdfComplete}"
                :loading="!isPdfComplete"
                :disabled="!isPdfComplete"
                :href="reportUrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ $t('buttons.valuation.open-pdf') }}
                <span
                  slot="loader"
                  class="mr-2"
                >{{ $t('buttons.valuation.generating-pdf') }}</span>
                <span
                  slot="loader"
                >
                  <v-progress-circular
                    :size="15"
                    :width="2"
                    indeterminate
                    color="disabled"
                  />
                </span>
              </v-btn>

              <v-btn
                v-if="detailedValuation"
                small
                outlined
                class="mr-lg-4 mb-3 mb-lg-0"
                color="primary"
                @click="toggleMapVisibility"
              >
                {{ isMapVisible ? $t('buttons.valuation.close-map') : $t('buttons.valuation.open-map') }}
              </v-btn>

              <v-spacer class="hidden-md-and-down" />

              <DeleteValuationDialog
                :has-lead="!!lead"
                :address="`${detailedValuation.inputs.address.street} ${detailedValuation.inputs.address.houseNumber}, ${detailedValuation.inputs.address.zip} ${detailedValuation.inputs.address.city}`"
                :valuation-id="detailedValuation.id"
                @deleted="$emit('deleted', detailedValuation.id)"
              />
            </div>
          </v-col>
        </v-row>
        <v-slide-y-transition>
          <valuation-map
            v-if="isMapVisible"
            class="mt-8"
            :valuation="detailedValuation"
          />
        </v-slide-y-transition>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import LEAD from './queries/getLead.gql'
import GET_VALUATION_PDF from './queries/getValuationPdf.gql'
import GET_VALUATION from '@/queries/GetValuation.gql'

import currencyMixin from '@/mixins/currency'
import { formatSpaceUnit } from '@/lib/spaceUnit'

import { exportValuation } from '@/lib/export'
import { propertyTypes, propertySubTypes } from '@/lib/propertyTypes'

import PropertyType from '@/lib/enums/valuation/PropertyType'
import Condition from '@/lib/enums/valuation/Condition'
import Quality from '@/lib/enums/valuation/Quality'
import PdfStatus from '@/lib/enums/valuation/PdfStatus'
import Confidence from '@/lib/enums/valuation/Confidence'

import ValuationMap from './ValuationMap.vue'
import DeleteValuationDialog from './DeleteValuationDialog.vue'
import AssignValuationDialog from './AssignValuationDialog.vue'
import ValuationResultChart from './ValuationResultChart.vue'
import InformationButton from '@/components/InformationButton.vue'
import feature from '@/mixins/feature'

const CONDITION = [
  { value: Condition.RENOVATION_NEEDED, text: 'valuation.condition.renovation-needed' },
  { value: Condition.WELL_MAINTAINED, text: 'valuation.condition.well-maintained' },
  { value: Condition.NEW_OR_RECENTLY_RENOVATED, text: 'valuation.condition.new-or-recently-renovated' }
]
const QUALITY = [
  { value: Quality.SIMPLE, text: 'valuation.quality.simple' },
  { value: Quality.NORMAL, text: 'valuation.quality.normal' },
  { value: Quality.HIGH, text: 'valuation.quality.high' },
  { value: Quality.LUXURY, text: 'valuation.quality.luxury' }
]

const CONFIDENCE = [
  { value: Confidence.LOW, text: 'valuation.confidence.low' },
  { value: Confidence.MEDIUM, text: 'valuation.confidence.medium' },
  { value: Confidence.HIGH, text: 'valuation.confidence.high' }
]

export default {
  components: {
    AssignValuationDialog,
    ValuationResultChart,
    InformationButton,
    ValuationMap,
    DeleteValuationDialog
  },
  mixins: [currencyMixin, feature],
  props: {
    valuation: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    panel: {
      type: Number,
      default: 0
    },
    openId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      detailedValuation: this.valuation,
      errorFetchingValuation: false,
      shouldFetchPdf: false,
      PropertyType,
      valuationDetailsLoaded: false,
      expanded: false,
      isPdfComplete: this.valuation.pdf.status === PdfStatus.COMPLETED,
      previewUri: '',
      isMapVisible: false
    }
  },
  computed: {
    user () {
      return this.detailedValuation.user
    },
    leadName () {
      return this.lead.firstname || this.lead.lastname ? `${this.lead.firstname} ${this.lead.lastname}` : this.lead.email
    },
    reportUrl () {
      const leadName = this.lead.firstname || this.lead.lastname ? `_${this.lead.firstname}${this.lead.lastname}` : ''
      const valuationDate = this.formatDate(this.detailedValuation.createdAt, 'dateISO')
      const fileName = `${valuationDate}_Immobilienbewertung${leadName}.pdf`
      return this.previewUri?.replace('report.pdf', encodeURIComponent(fileName)) + '&attachment=false'
    },
    canAssignLeadToValuation () {
      return this.getFeature(this.featureNames.VALUATION)?.config.canAssignLeadToValuation
    },
    showLeadIcon () {
      return this.detailedValuation && this.detailedValuation.leadId && this.isPdfComplete === false
    },
    propertyTypeLabel () {
      return propertyTypes[this.detailedValuation.inputs.type]
    },
    propertySubTypeLabel () {
      if (!this.detailedValuation) return
      return propertySubTypes[this.detailedValuation.inputs.subtype]
    },
    condition () {
      return CONDITION.find(obj => { return obj.value === this.detailedValuation.inputs.condition }).text
    },
    quality () {
      return QUALITY.find(obj => { return obj.value === this.detailedValuation.inputs.quality }).text
    },
    confidence () {
      return this.detailedValuation.results ? CONFIDENCE.find(obj => { return obj.value === this.detailedValuation.results.property.valueConfidence }).text : 'valuation.labels.price-calculating'
    },
    readablePrice () {
      return this.detailedValuation.results ? this.formatPrice(this.detailedValuation.results.property.value, this.detailedValuation.results.currency) : this.$t('valuation.labels.price-calculating')
    },
    readableAnnualIncome () {
      return this.detailedValuation.results ? this.formatPrice(this.detailedValuation.inputs.annualRentIncome, this.detailedValuation.results.currency) : this.$t('valuation.labels.income-formatting')
    },
    readablePropertyType () {
      switch (this.detailedValuation.inputs.type) {
        case PropertyType.HOUSE:
          return this.$t('valuation.property-type.house')
        case PropertyType.APARTMENT:
          return this.$t('valuation.property-type.apartment-normal')
        case PropertyType.MULTI_FAMILY_HOUSE:
          return this.$t('valuation.property-type.multi-family-house')
        default:
          return this.$t('valuation.property-type.default')
      }
    }
  },
  watch: {
    openId () {
      if (this.detailedValuation.id === this.openId) {
        this.expand()
      }
    }
  },

  created () {
    if (this.index === this.panel) {
      this.expand()
    }
    if (this.detailedValuation.id === this.openId) {
      this.expand()
    }
  },

  methods: {
    formatSpaceUnit,
    toggleMapVisibility () {
      this.isMapVisible = !this.isMapVisible
    },
    updateValuation (leadId) {
      this.detailedValuation.leadId = leadId
      this.shouldFetchPdf = true
    },
    async downloadValuation () {
      await exportValuation({ id: this.detailedValuation.id })
    },
    expand () {
      this.expanded = true
      this.$emit('expand', this.detailedValuation.id)
    }
  },
  apollo: {
    lead: {
      query: LEAD,
      variables () {
        return {
          id: this.detailedValuation.leadId
        }
      },
      skip () {
        return !this.detailedValuation.leadId
      }
    },
    detailedValuation: {
      query: GET_VALUATION,
      variables () {
        return {
          valuationId: this.detailedValuation.id
        }
      },
      skip () {
        return !this.expanded || this.valuationDetailsLoaded || !this.detailedValuation.id
      },
      update (data) {
        if (!data) return
        this.valuationDetailsLoaded = true
        this.previewUri = data.valuation.pdf.file?.cdnUrl
        return data.valuation
      }
    },
    fetchPDF: {
      query: GET_VALUATION_PDF,
      variables () {
        return {
          valuationId: this.detailedValuation.id
        }
      },
      skip () {
        return !this.shouldFetchPdf || !this.detailedValuation.id || this.errorFetchingValuation
      },
      update (data) {
        if (!data) return
        if (data.pdf.pdf.status === PdfStatus.COMPLETED) {
          this.shouldFetchPdf = false
          this.isPdfComplete = true
          this.previewUri = data.pdf.pdf.file.cdnUrl
        }
        this.detailedValuation.pdf = data.pdf
      },
      error () {
        this.errorFetchingValuation = true
      },
      pollInterval: 10000
    }
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
